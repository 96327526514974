import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Seo from '../components/SEO/seo';
import Layout from '../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Terms of use",
  "path": "/terms-of-use"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Terms of use`}</h1>
    <p>{`Thanks for using Flow! We believe it is important to understand the Terms of Use. Read this carefully before using our services. Any questions left? Reach out out to us via `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <h2>{`1. About us, Our Services and Our Terms`}</h2>
    <p>{`You are obviously curious to know what the conditions are for using Flow. We want to be clear and transparent on this. To start with: we are Flow Money Automation B.V., a Dutch company that has set itself the goal to help you get a grip on your financial affairs. To achieve this goal, we provide the following services (hereinafter: "Our Services"):`}</p>
    <ul>
      <li parentName="ul">{`Our website `}<a parentName="li" {...{
          "href": "https://flowyour.money"
        }}>{`https://flowyour.money`}</a>{` (the “Site”);`}</li>
      <li parentName="ul">{`The Flow mobile app (the "App"); and`}</li>
      <li parentName="ul">{`All other services available through or via the App or the Site; and`}</li>
      <li parentName="ul">{`All subsequent versions and updates of the Site, the App or other software, or any new websites.`}</li>
    </ul>
    <p>{`These Terms of Use and the Privacy Statement (together: "Our Terms") apply to Our Services. In other words, if you use Our Services (i.e. the Site or the App, etc.), you automatically agree to Our Terms. You are required to read Our Terms carefully and it is not allowed to use Our Services if you do not agree to them 100%. The applicability of other conditions is expressly rejected.`}</p>
    <p>{`If you want to reach out to us, you have our details here:`}</p>
    <p>{`Flow Money Automation B.V
Riperwei 54
8406 AK Tijnje
Dutch Chamber of Commerce: 72829796
E-mail: `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <p>{`Flow has a so-called "PSD2 license". This is a license from the Dutch Central Bank with which we can offer our payment services. PSD2 stands for "Payment Services Directive 2" and is a guideline that ensures that European consumers can ask their bank to share data with companies such as Flow, in a safe and responsible manner. From July 13, 2020, Flow has a license to view your account information (Service 8) and to perform payment initiations (Service 7). You can find us in the DNB Register of Payment Institutions under number: R166735`}</p>
    <p>{`In addition, Flow is ISO 27001: 2013 certified. We have done this to guarantee your privacy. In this way we can demonstrate that information security is an important element at Flow. Every year we are assessed on this by external professional auditors who keep us sharp. `}<a parentName="p" {...{
        "href": "/ISO-IEC_27001-ENG-10000319441-MSC-UKAS-NLD-3-20241129.pdf"
      }}>{`You can find our certificate here`}</a>{`.`}</p>
    <p>{`We reserve the right to unilaterally amend Our Terms. You may not be able to use Our Services until you accept the latest version of Our Terms. We will notify you when there are changes to the Terms. These Terms of Use were published on August 20, 2020, but the latest version can always be viewed at: `}<a parentName="p" {...{
        "href": "https://flowyour.money/terms"
      }}>{`https://flowyour.money/terms`}</a>{`.`}</p>
    <h2>{`2. About you and your account`}</h2>
    <p>{`Then about you. You are our customer, which means that you are 18 years of age or older and have full power of disposition and legal capacity. If you are not (yet), you will have to wait a little longer: you are then prohibited from using Our Services.`}</p>
    <p>{`To use (part of) Our Services you will need an account. The following rules apply:`}</p>
    <ol>
      <li parentName="ol">{`You are obliged to register under your own real and full name and to provide us with all information correctly and completely;`}</li>
      <li parentName="ol">{`If something changes in your situation, as a result of which your account details are no longer correct, you are obliged to inform us immediately. You may contact us at `}<a parentName="li" {...{
          "href": "mailto:hi@flowyour.money"
        }}>{`hi@flowyour.money`}</a>{`;`}</li>
      <li parentName="ol">{`It is not allowed to let others use your account;`}</li>
      <li parentName="ol">{`You may only allow people who are officially authorized to use your account to access Flow;`}</li>
      <li parentName="ol">{`It is not allowed to link bank accounts to your account that are not yours;`}</li>
      <li parentName="ol">{`It is not allowed to open an account for another person. With the exception of companies that are authorized by their clients to open an account and use Flow;`}</li>
      <li parentName="ol">{`The automatic creation of accounts, for example by means of a script, bot or an automated process, is strictly prohibited; and`}</li>
      <li parentName="ol">{`The (commercial or non-commercial) exploitation of Our Services on behalf of third parties is prohibited`}</li>
    </ol>
    <p>{`You warrant and are fully responsible for ensuring that all information you provide to us is accurate and current and that we can reach you at the contact details provided by you. You indemnify us against (potential) damage resulting from your violation of this article.`}</p>
    <p>{`In principle, everyone (older than 18 years, etc.) is of course welcome as a customer, but if we believe that you are not using Our Services as intended (for example if you act in violation of Our Terms), then we have the unilateral right to terminate your account. This means, among other things, that we have the right to immediately block access to Our Services (and therefore to your account) and that we can delete any data and (personal) data. We are not responsible for any consequences thereof.`}</p>
    <p>{`For example, when using Our Services, we check whether the identity you provide is correct and if you are not listed on PEP or sanction lists (PEPs are politically exposed persons). We also check whether you are not involved in money laundering and / or terrorist financing. If we cannot perform this check properly because you do not provide us with the correct information or if the checks show that we have to refuse access, we may terminate your account.`}</p>
    <p>{`Our Services are not designed to meet your individual requirements. It is your own responsibility to check whether Our Services suit you and can help you further.`}</p>
    <h2>{`3. Security of your account`}</h2>
    <p>{`You are required to do everything in your power to secure your account, including at least the following:`}</p>
    <ol>
      <li parentName="ol">{`The device that gives you access to Our Services, such as a mobile phone, tablet, laptop or other device (hereinafter: the "Device"), is used by you personally and you keep an eye on it when you borrow it to someone. Under no circumstances you allow others to use Our Services on your Device;`}</li>
      <li parentName="ol">{`The Device is protected by a PIN code or password and you make sure that this protection is adequate, not known to others and cannot be seen by others;`}</li>
      <li parentName="ol">{`You will change your password or pin regularly;`}</li>
      <li parentName="ol">{`You will keep your password and account login information safe and secure and will not share it with third parties;`}</li>
      <li parentName="ol">{`You will keep the e-mail accounts associated with Flow secure;`}</li>
      <li parentName="ol">{`If your PIN code is stolen or lost, or someone has access to your email account, you will contact us directly so we can take measures. In that case, you can always reach us directly at `}<a parentName="li" {...{
          "href": "mailto:hi@flowyour.money"
        }}>{`hi@flowyour.money`}</a>{`;`}</li>
      <li parentName="ol">{`The Device you use must be equipped with the latest safety and performance requirements. You are required to always download and install the latest (security) updates; and`}</li>
      <li parentName="ol">{`We will never ask for passwords or PINs. You will therefore never provide this information if requested by e-mail, telephone, SMS, or otherwise.`}</li>
    </ol>
    <p>{`You will ensure that you observe the above security measures and indemnify us against any damage resulting from any breach thereof. If you do not comply with the safety requirements and if problems arise, we can of course not be held liable for this.`}</p>
    <h2>{`4. Availability of Our Services`}</h2>
    <p>{`We will do our best to have Our Services available at all times, but cannot guarantee this at all times. Firstly, we are, of course, dependent on the banks we work with. So if things go wrong at some point, it doesn't have to be our fault. In addition, of course we might always have to deal with unexpected situations or malfunctions. We are not liable for damage caused by the fact that Our Services are not available. By all means, we shall not be liable for damage caused by problems arising from faulty software, hardware, or network connections of the Device.`}</p>
    <p>{`We want to continue to improve Our Services. We therefore have the right to make changes to Our Services (such as our software) and to other affairs. We therefore also have the right to (temporarily) take Our Services out of use or restrict them in case this is necessary, for example, for maintaining or modifying them, without giving rise to any right to damages or compensation.`}</p>
    <p>{`We have compiled the content of Our Services (including, for example the texts on the Website or in the App) with the greatest possible care, but excluding any explicit or tacit warranties, commitments or indemnities of any kind. We cannot guarantee the relevance, reliability and accuracy of the content of the information displayed. This is partly because a lot of information comes from the (software of) the banks we work with. You are expected to be confidential and attentive. If you see an error in a text somewhere, or if you suspect a safety error, you are obliged to report this to us via `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a>{`.`}</p>
    <h2>{`5. Prohibition of abuse of Our Services`}</h2>
    <p>{`All texts, images, software, (source) code, etc. that are part of Our Services, regardless of whether or not we have any intellectual property rights to them, are collectively referred to as "Our Content". It is prohibited to make Our Content available to any third party, to reproduce, distribute, modify, transmit or in any way incorporate Our Content into other material, or to use it in any other way other than personally and in accordance with the purpose of Our Services. In addition, it is strictly prohibited to:`}</p>
    <ol>
      <li parentName="ol">{`Use Our Services contrary to the purpose or intent of the law or Our Terms;`}</li>
      <li parentName="ol">{`Infringe on (our) intellectual property;`}</li>
      <li parentName="ol">{`Offer services that could be confused with Our Services;`}</li>
      <li parentName="ol">{`Use Our Services to cause damage or inconvenience;`}</li>
      <li parentName="ol">{`Attempt to decipher or intercept data (e.g. by breaking the encryption); or`}</li>
      <li parentName="ol">{`Attempt to reproduce or modify the content of Our Services.`}</li>
    </ol>
    <h2>{`6. Rights and license`}</h2>
    <p>{`You receive from us a non-exclusive, non-transferable, revocable and non-sublicensable license to use Our Services during and within the framework of the agreement entered into with us to which Our Terms apply. For example, the App is not yours, but you may use it. Your user license and all other rights under that agreement will expire if you do not or no longer have an agreement with us.`}</p>
    <p>{`We do not become the owner of any information you post or provide through Our Services, but you grant us a non-exclusive license free of charge to use that information worldwide, subject to our Privacy Statement.`}</p>
    <h2>{`7. Limitation of liability`}</h2>
    <p>{`All information, texts and possibilities offered by Our Services are intended as information and not as financial or professional advice. It is your own responsibility to make sound choices about your financial situation and, where necessary, to involve experts.`}</p>
    <p>{`You should be aware that using Our Services can involve risks. By using Our Services you acknowledge and accept these risks. For example, it is possible that you give the App a wrong assignment and a lot of money ends up in the wrong account. We help you manage and automate your accounts, but we are never responsible for the cash flows or activities on your account(s). We are also not liable for damage or loss as a result of (financial flows due to) failures, data leaks, possible faults or other imperfections including, but not limited to, defects, malware, misuse of the Software by third parties and/or the loss of (personal) data. The use of Our Services is entirely at your own risk. The user license comes without any form of guarantee, although we do our best to protect our systems as much as possible.`}</p>
    <p>{`We are by all means not liable for damage that is (partly) the result of acts or omissions of third parties (for example banks) or of yourself. The latter is by all means the case if you violate or have violated any of the provisions of Our Terms.`}</p>
    <h2>{`8. Other provisions`}</h2>
    <p>{`You cannot transfer rights derived from our legal relationship to a third party.`}</p>
    <p>{`Should any provision of these Terms of Use prove to be void or non-binding, Flow and you remain bound by the other provisions of these Terms of Use. The parties will replace the invalid or non-binding provision(s) by a provision that is binding and whose purport is as much as possible the same as that of the provision(s) to be replaced, while taking into account the purpose of these Terms of Use.`}</p>
    <p>{`These Terms of Use, in particular the limitation of liability, may also be invoked by all our employees, affiliates, directors and shareholders.`}</p>
    <h2>{`9. Law, complaints and procedures`}</h2>
    <p>{`Our agreement and Our Terms are governed by Dutch law. In the event of any conflict between this English language version and the original Dutch language version, the Dutch language version shall govern and control.`}</p>
    <p>{`We hope you are always satisfied with us. If you do encounter any problems, you are obliged to report them to us first (which prevents unnecessary legal proceedings). You can mail your complaint or problem to `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money."
      }}>{`hi@flowyour.money.`}</a>{` From that moment on we have 14 days to clear the air, tackle the problem, or send flowers 💐.`}</p>
    <p>{`If we haven't straightened it out yet after that, you are of course free to go to court. All disputes, contractual or otherwise, between you and us are exclusively subject to the competent court in Amsterdam (The Netherlands).`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      